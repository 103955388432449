import Image from "next/legacy/image"
import { useRouter } from "next/router"
import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalBody,
  ModalFooter,
  Button,
  UseDisclosureReturn,
  ModalHeader,
  Box,
  useTheme,
} from "@chakra-ui/react"

const ThankYouDialogBox = ({
  isOpen,
  onClose,
  rating,
}: {
  isOpen: boolean
  onClose: UseDisclosureReturn["onClose"]
  rating: number
}): JSX.Element => {
  const theme = useTheme()
  const router = useRouter()
  const redirectToDashboard = () => router.asPath === '/' ? router.reload() : router.push('/')

  const ModalBodyByRating = () => {
    if (rating < 4) {
      return (
        <>
          <Text mt="15px">
            <strong>{theme.pageOptions.thankYouLowRateModal.title}</strong>
          </Text>

          <Text
            mt="15px"
            dangerouslySetInnerHTML={{ __html: theme.pageOptions.thankYouLowRateModal.text }}
          ></Text>
        </>
      )
    }
    return (
      <Text
        dangerouslySetInnerHTML={{ __html: theme.pageOptions.thankYouModal.text }}
        mt="15px"
      ></Text>
    )
  }

  const ModalFooterByRating = () => {
    if (rating < 4) {
      return (
        <>
          <Button variant="homehero" mb="20px" fontSize="18px" onClick={() => router.push("/help")}>
            Share your feedback
          </Button>
          <Button
            mt="20px"
            variant="link"
            marginBottom="20px"
            fontSize="18px"
            textDecoration="underline"
            onClick={() => {
              onClose()
              redirectToDashboard()
            }}
          >
            Close and go to Portal Home
          </Button>
        </>
      )
    }
    return (
      <Button
        mt="20px"
        variant="link"
        marginBottom="20px"
        fontSize="18px"
        textDecoration="underline"
        onClick={() => redirectToDashboard()}
      >
        Close and go to Portal Home
      </Button>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mr="12px" ml="12px">
        <Box ml="12px">
          <div className="headerLogoContainer">
            <Image
              alt="Logo"
              src={theme.logoImage.url}
              width={theme.logoImage.width}
              height={theme.logoImage.height}
            />
          </div>
        </Box>
        {rating < 4 && (
          <ModalHeader>
            <Text p={{ md: "0px 70px 0 70px", sm: "0 15px" }} fontSize="28px" lineHeight="40px">
              <strong>Thank You For Your Feedback</strong>
            </Text>
          </ModalHeader>
        )}
        <ModalBody p={{ md: "0 70px 0 70px", sm: "0 15px" }} m={{ base: "initial", sm: "0 25px" }}>
          <ModalBodyByRating />
        </ModalBody>

        <ModalFooter
          p={{ md: "0 70px 0 70px", sm: "0 15px" }}
          m="40px 0 20px 0"
          textAlign="center"
          flexDirection="column"
        >
          <ModalFooterByRating />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ThankYouDialogBox
