import {
  useBreakpoint as useChakraBreakpoint,
  useBreakpointValue as useChakraBreakpointValue,
} from "@chakra-ui/react"

// In Jest Test: Chakra useBreakpointValue and useBreakpoint return
// undefined at first load, so let's set a static value
// to prevent broken from test
//
const useBreakpoint = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return process.env.NODE_ENV === "test" ? "base" : useChakraBreakpoint()
}

export const useBreakpointValue = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return process.env.NODE_ENV === "test" ? "desktop" : useChakraBreakpointValue(props)
}

export default useBreakpoint
