import { Box } from "@chakra-ui/react"
import TrustPilotIcon from "app/icons/TrustPilotIcon"
import React, { useState } from "react"
import { useTenant } from "./common/Tenant"

const TrustPilotRating = ({ action }: { action: (index: number) => void }): JSX.Element => {
  const {tenant} = useTenant()

  let ratingStars: JSX.Element[] = []
  const [currentHoverStar, setCurrentHoverStar] = useState<number | null>(null)
  const [currentRating, setCurrentRating] = useState<number | null>(null)

  const color = tenant?.name === "divorcenet" ? "brand.orange" : "brand.blue"

  for (let index = 1; index <= 5; index++) {
    ratingStars.push(
      <TrustPilotIcon
        data-cy="star-rating-icon"
        onMouseEnter={() => onHover(index)}
        onMouseLeave={() => onLeave()}
        onClick={() => onClick(index)}
        key={index}
        m={3}
        boxSize={16}
        color={
          currentHoverStar
            ? index <= currentHoverStar
              ? color
              : "unset"
            : currentRating && index <= currentRating
            ? color
            : "unset"
        }
        cursor="pointer"
      />
    )
  }

  function onHover(index: number): void {
    setCurrentHoverStar(index)
  }

  function onLeave(): void {
    setCurrentHoverStar(null)
  }

  function onClick(index: number): void {
    setCurrentRating(index)
    action(index)
  }

  return <Box>{ratingStars}</Box>
}

export default TrustPilotRating
