'use client'
import { useMutation } from "@blitzjs/rpc"
import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import ReviewDialogBox from "./ReviewDialogBox"
import ThankYouDialogBox from "./ThankYouDialogBox"
import updateReviewService from "app/core/mutations/review"
import { ProductReviewProp } from "app/utils/divorceHomePage"
import { isServer } from "blitz"
import { useTenant } from "./common/Tenant"

const RatingComponent = ({
  userFirstName,
  isComplete,
  product,
}: {
  userFirstName: string
  isComplete: boolean
  product: ProductReviewProp
}) => {
  const {tenant} = useTenant()
  const {
    isOpen: isOpenReviewModal,
    onOpen: onOpenReviewModal,
    onClose: onCloseReviewModal,
  } = useDisclosure()
  const {
    isOpen: isOpenThanksModal,
    onOpen: onOpenThanksModal,
    onClose: onCloseThankYouModal,
  } = useDisclosure()

  const [rating, setRating] = useState<number>(0)
  /*
   * expecterd values
   * 0: default. the modal is yet to be shown
   * -1: the user dismissed the modal once
   * -2: the user dismissed the modal twice, so the modal wont be shown again
   * >0: the user sent the rating
   */
  const isFeedbackSent = product.review === -1 || product.review === 0
  const [openReviewModal, setOpenReviewModal] = useState<boolean>(!isFeedbackSent)
  const [updateReviewMutation] = useMutation(updateReviewService)
  let dismissedModal = false
  if (!isServer) {
    dismissedModal = Boolean(sessionStorage?.getItem("dismissedRatingModal"))
  }
  function onRatingChange(index: number): void {
    const data = {
      product: product,
      newReview: index,
      tenant: tenant?.name,
    }

    setRating(index)

    updateReviewMutation(data)

    setTimeout(() => {
      onCloseReviewModal()
      onOpenThanksModal()
    }, 1000)
  }

  if (!openReviewModal && isComplete && !isOpenReviewModal && !dismissedModal) {
    setOpenReviewModal(true)
    onOpenReviewModal()
  }

  return (
    <>
      <ReviewDialogBox
        isOpen={isOpenReviewModal}
        action={onRatingChange}
        onClose={onCloseReviewModal}
        updateReview={updateReviewMutation}
        name={userFirstName}
        product={product}
      />
      <ThankYouDialogBox
        isOpen={isOpenThanksModal}
        onClose={onCloseThankYouModal}
        rating={rating}
      />
    </>
  )
}

export default RatingComponent
