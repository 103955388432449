import { useRouter } from "next/router"
import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalBody,
  useDisclosure,
  ModalFooter,
  Button,
} from "@chakra-ui/react"
import ProductIcon from "app/components/ProductElements/ProductIcon"
import { useTenant } from "./common/Tenant"

const AccessDialogBox = ({ hasProduct, expired }: { hasProduct: boolean; expired: boolean }) => {
  const { onClose } = useDisclosure()
  const {tenant} = useTenant()

  const router = useRouter()
  const message = hasProduct
    ? "Your credit card failed on the last installment transaction. Kindly update your credit card details and complete the installment to reactivate your account."
    : "In order to utilize our state-of-the-art online divorce system, please purchase a subscription at our online store."
  const button = hasProduct ? "Manage your installment" : "Go To Store"
  const linkObj = {
    threesd: process.env.NEXT_PUBLIC_THREESD_SHOPIFY_STOREFRONT_URL,
    divorcenet: process.env.NEXT_PUBLIC_DIVNET_SHOPIFY_STOREFRONT_URL,
    nolodivorce: process.env.NEXT_PUBLIC_NOLODIVORCE_SHOPIFY_STOREFRONT_URL,
  }
  const link = hasProduct ? "/api/shopify/login" : "https://" + linkObj[tenant?.name]

  return (
    <Modal isOpen={!hasProduct || expired} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody m={25} textAlign="center" pt="75px">
          <ProductIcon iconName="redx" alt="No Subscription Found" size={42} text="" />
          <Text mt="15px">
            <strong>Sorry, you don&apos;t have access</strong>
            <br />
            <br />
            {message}
          </Text>
        </ModalBody>

        <ModalFooter flexDirection="column">
          <Button
            onClick={() => router.push(link)}
            variant="homehero"
            fontSize="18px"
            marginLeft="auto"
            marginRight="auto"
            marginTop="33px"
            marginBottom={expired ? "20px" : "63px"}
            top="0"
          >
            {button}
          </Button>
          {expired && (
            <Button
              variant="link"
              marginBottom="20px"
              fontSize="18px"
              textDecoration="underline"
              onClick={() => router.push("/help")}
            >
              Contact Us
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AccessDialogBox
