'use client'
import Image from "next/legacy/image"
import { useRouter } from "next/router"
import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalBody,
  ModalFooter,
  Button,
  UseDisclosureReturn,
  Box,
  useTheme,
} from "@chakra-ui/react"
import TrustPilotRating from "./TrustPilotRating"
import { DataProp } from "app/core/mutations/review"
import { ProductReviewProp } from "app/utils/divorceHomePage"
import * as Sentry from "@sentry/react"
import { isServer } from "blitz"
import { useTenant } from "./common/Tenant"

const ReviewDialogBox = ({
  isOpen,
  name,
  onClose,
  updateReview,
  action,
  product,
}: {
  isOpen: boolean
  name: string
  onClose: UseDisclosureReturn["onClose"]
  updateReview: (data: DataProp) => void
  action: (index: number) => void
  product: ProductReviewProp
}): JSX.Element => {
  const theme = useTheme()
  const {tenant} = useTenant()
  const router = useRouter()
  const redirectToDashboard = () => router.asPath === '/' ? router.reload() : router.push('/')


  function dismissModal() {
    const data = { product: product, newReview: --product.review, tenant: tenant?.name }

    try {
      updateReview(data)
      if (!isServer) {
        sessionStorage.setItem("dismissedRatingModal", String(true))
      }
    } catch (error) {
      Sentry.captureException(error)
      console.log(error)
    }
    onClose()
    redirectToDashboard()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mr="12px" ml="12px">
        <Box ml="12px">
          <div className="headerLogoContainer">
            <Image
              alt="Logo"
              src={theme.logoImage.url}
              width={theme.logoImage.width}
              height={theme.logoImage.height}
            />
          </div>
        </Box>
        <ModalBody
          p={{ md: "0 70px 0 70px", sm: "0 15px" }}
          m={{ base: "initial", sm: "0 25px" }}
          textAlign="center"
        >
          <Text mt="15px">
            <strong>
              Well done, {name}. Your online divorce paperwork is officially finished.
            </strong>
          </Text>
          <Text fontSize="14px" mt="15px">
            You&apos;ve just completed a huge step in putting your divorce behind you. Thank you
            again for choosing us to help you through it.
          </Text>
          <Text
            p={{ md: "50px 20px 0 20px", base: "30px 20px 0 20px" }}
            fontSize="28px"
            lineHeight="40px"
          >
            <strong>How did it go? Rate your experience below</strong>
          </Text>
          <TrustPilotRating action={action} />
        </ModalBody>

        <ModalFooter
          p={{ md: "0 70px 0 70px", sm: "0 15px" }}
          mt="40px"
          textAlign="center"
          flexDirection="column"
        >
          <Text>
            Your feedback is important and helps us improve our service for yourself and others
            seeking help with their online divorce.
          </Text>
          <Button
            mt="20px"
            variant="link"
            marginBottom="20px"
            fontSize="18px"
            textDecoration="underline"
            onClick={dismissModal}
          >
            Skip and go to Portal Home
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReviewDialogBox
