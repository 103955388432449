import Image from "next/legacy/image"
import { Text, Box } from "@chakra-ui/react"

const ProductIcon = ({
  iconName,
  alt,
  size,
  text,
}: {
  iconName: string
  alt: string
  size: number
  text: string
}) => (
  <Box marginRight="auto" marginLeft="auto">
    <Image alt={alt} src={`/images/icons/${iconName}.svg`} height={size} width={size}></Image>
    <Text textTransform="uppercase" fontSize="10px" textAlign="center">
      {text}
    </Text>
  </Box>
)
export default ProductIcon
